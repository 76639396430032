.frontpage-header {
  --content-grid--row-gap: 0;
  text-align: left;

  @media (width > 576px) {
    text-align: center;
  }

  h1 {
    width: auto;
    max-width: 100%;
  }
}
