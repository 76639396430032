@import url("@item-enonic-types/lib-blocks/dist/blocks-accordion.css");

.blocks-accordion {
  --blocks-accordion--background-color: var(--color-theme-base-default);
  --blocks-accordion--background-color-hover: var(--color-theme-base-hover);
  --blocks-accordion--background-color-open: var(--color-theme-base-hover);
  --blocks-accordion--text-color: var(--color-theme-contrast-default);
  --blocks-accordion--icon-color: var(--color-accent-text-subtle);
  --blocks-accordion--icon-url: url("../../icons/plus.svg");
  --blocks-accordion--icon-size: 24px;

  h2 {
    margin-bottom: var(--margin-m);
  }

  details {

    summary {
      list-style: none;
      cursor: pointer;

      &::-webkit-details-marker {
        display: none
      }

      h3 {
        display: flex;
      }

      h3::after {
        content: "";
        display: inline-block;
        float: right;
        width: 1em;
        min-width: var(--blocks-accordion--icon-size);
        height: var(--blocks-accordion--icon-size);
        margin: auto 0 auto auto;
        background-color: var(--blocks-accordion--icon-color);
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: var(--icon-position);
        mask-image: var(--blocks-accordion--icon-url);

        --icon-margin: var(--margin-xs);
      }
    }

    &[open] {
      --blocks-accordion--icon-url: url("../../icons/minus.svg");
    }
  }

  &.theme-neutral {
    --blocks-accordion--flow-space: 0;
    --blocks-accordion--padding: var(--blocks-spacing-sm) 0;

    details > summary:hover {
      text-decoration: underline;
    }
  }
}

