@import url("@item-enonic-types/lib-blocks/dist/blocks-card.css");
@import url("@item-enonic-types/lib-blocks/dist/blocks-cards.css");

.blocks-cards--cards {
  --blocks-cards--gap: var(--content-grid--row-gap, var(--blocks-spacing-lg));
}

.blocks-card {
  --blocks-card--background-color: var(--color-theme-base-default);
  --blocks-card--background-color-hover: var(--color-theme-base-hover);
  --blocks-card--background-color-active: var(--color-theme-base-active);
  --blocks-card--text-color: var(--color-theme-contrast-default);
  --blocks-card--heading-color: var(--color-theme-contrast-default);
  --blocks-card--kicker-color: var(--color-accent-text-subtle);
  --blocks-card--card-padding: var(--blocks-spacing-lg);
  --blocks-card--body-padding: 0;
  --blocks-card--gap: var(--blocks-card--card-padding);

  .blocks-card--body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing-xxs);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-regular);
  }

  .blocks-card--kicker {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-l);
    letter-spacing: .42px;
  }

  .blocks-card--image img:not([src$=".svg"]) {
    /* Should use `object-fit: cover;`, but images with text are cropped. This can be fixed if they are replaced with SVGs */
    object-fit: contain;
  }
}

.theme-neutral > .blocks-card {
  --blocks-card--background-color: transparent;
  --blocks-card--background-color-hover: transparent;
  --blocks-card--background-color-active: transparent;
  --blocks-card--text-color: currentcolor;
  --blocks-card--heading-color: currentcolor;
  --blocks-card--card-padding: 0;
  --blocks-card--gap: var(--blocks-spacing-md);
}

.content-type-site .blocks-card {
  /* Use 40px padding on small screens for cards on Frontpage */
  --blocks-card--card-padding: var(--spacing-m);
}
