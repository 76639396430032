.frontpage-statistics {
  --grid-column-width: 130px;

  display: grid;
  grid-template-columns: var(--grid-column-width) var(--grid-column-width);
  position: relative;
  padding-inline: var(--spacing-s);
  row-gap: var(--margin-s);

  @media only screen and (width > 576px) and (width < 720px) {
    justify-content: center;
  }

  @media only screen and (width > 720px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .frontpage-statistic {
    position: relative;
    white-space: nowrap;

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      right: 0;
      top: 50%;
      width: 21px;
      height: 54px;
      background-color: var(--color-accent-base-default);
      transform: translateY(-50%);
      mask-image: url("../../../assets/icons/slash.svg");
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
    }

    @media only screen and (width < 720px) {

      &:nth-child(even) {
        padding-left: var(--padding-20);

        &::after {
          display: none;
        }
      }
    }

    @media only screen and (width > 720px) {
      padding-right: var(--padding-40);
      padding-left: var(--padding-20);
    }

    &:last-child {
      padding-right: 0;

      @media only screen and (width > 720px) {

        &::after {
          display: none;
        }
      }
    }
  }

  .statistics-value {
    font-size: var(--font-size-925);
    font-weight: var(--font-weight-light);
  }
}
