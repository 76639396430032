:root {
  /* Spacing mobile */
  --blocks-spacing-sm: var(--spacing-xs);
  --blocks-spacing-md: var(--spacing-xs);
  --blocks-spacing-lg: var(--spacing-s);
  --blocks-spacing-xl: var(--spacing-m);
  --blocks-spacing-xxl: var(--spacing-l);
}

@media (width > 620px) {

  :root {
    /* Spacing desktop */
    --blocks-spacing-sm: var(--spacing-xs);
    --blocks-spacing-md: var(--spacing-s);
    --blocks-spacing-lg: var(--spacing-m);
    --blocks-spacing-xl: var(--spacing-l);
    --blocks-spacing-xxl: var(--spacing-xl);
  }
}
