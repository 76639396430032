:root {
  scroll-padding-top: var(--margin-s);
}

body {
  background-color: var(--color-neutral-background-default);
  font-family: var(--font-sans-serif);
  font-size: var(--font-size-m, 16px);
  color: var(--color-neutral-text-default)
}

:where(button, input[type="submit"]) {
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: inherit;
  color: currentcolor;
  text-align: left;
  cursor: pointer;
}

a {
  color: currentcolor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.linker-arrow, a.linker-arrow-big {
  color: var(--color-neutral-text-default)
}

/* Headings */

h1 {
  --flow-space: var(--margin-xs);

  text-wrap: balance;
  width: var(--width-6-col);
  max-width: 100%;
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs); /* font height / font size example: 52/40 = 1.3 */
}

h2 {
  --flow-space: var(--margin-m);

  text-wrap: balance;
  font-size: var( --font-size-xl);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);
}

h3, .h3 {
  --flow-space: var(--margin-s);

  text-wrap: balance;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
}

.intro {
  width: var(--width-7-col);
  max-width: 100%;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-s);
}

/* Paragraph */

.p, p {
  font-weight: var(--font-weight-regular);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  line-height: var(--line-height-l);
  /* stylelint-disable */
  font-smooth: always;
  /* stylelint-enable */

  @media only screen and (width > 576px) {
    --p-font-size: var(--font-size-m);
  }
}

.p2 {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  line-height: var(--line-height-xs);

  > p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.quote-small {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semibold);
  font-style: normal;
  line-height: var(--line-height-m);
}

.quote-big {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  text-wrap: balance;
}

/* Other */

.list-title {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
}

.label-small {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-l);
  letter-spacing: 0.42px;
}

.label-big {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xl);
}

.link-basic {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-xl);
}

.link-basic-small {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-xl);
}

