.image-wrapper {
  --block-images--expand-link-size: 33px;

  position: relative;

  .expand-link {
    position: absolute;
    right: var(--margin-xs);
    bottom: var(--margin-xs);
    width: var(--block-images--expand-link-size);
    height: var(--block-images--expand-link-size);
    background-color: var(--color-neutral-background-default);

    &::after {
      --icon-position: center;

      width: 100%;
      height: 100%;
      margin-left: auto;
      mask-size: var(--block-images--expand-link-size);
    }
  }

  .icon-expand-link {
    --icon-url: url("../../../../assets/icons/expand-link.svg");
  }
}
