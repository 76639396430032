.error-page {
  --error-image-size: 240px;
  --error-image-desktop-size: 300px;

  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 20px;

  .error {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;

    @media only screen and (width > 900px) {
      flex-direction: row;
      width: 100%;
      max-width: 1000px;
      margin-top: 200px;
    }

    .error-info {
      display: flex;
      place-content: center center;
      flex-direction: column;
      padding-top: 100px;
      background-image: url("../../../../assets/icons/quote.svg");
      background-repeat: no-repeat;
      background-size: 48px;
      text-align: center;

      @media only screen and (width > 900px) {
        justify-content: flex-start;
        max-width: 350px;
        text-align: left;
      }

      p {
        margin-top: 20px;
      }

      h1 {
        width: 100%;
      }
    }

    img {
      width: var(--error-image-size);
      height: var(--error-image-size);
      margin: auto;
      margin-top: 40px;
      animation: vibrate-3 1.5s linear infinite both;

      @media only screen and (width > 900px) {
        width: var(--error-image-desktop-size);
        height: var(--error-image-desktop-size);
      }
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-30 16:51:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-3
 * ----------------------------------------
 */
 @keyframes vibrate-3 {

  0% {
    transform: translate(0);
  }

  10% {
    transform: translate(-2px, -2px);
  }

  20% {
    transform: translate(2px, -2px);
  }

  30% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(2px, 2px);
  }

  50% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, -2px);
  }

  70% {
    transform: translate(-2px, 2px);
  }

  80% {
    transform: translate(-2px, -2px);
  }

  90% {
    transform: translate(2px, -2px);
  }

  100% {
    transform: translate(0);
  }
}
