@import url("@item-enonic-types/lib-blocks/dist/blocks-quote.css");

.blocks-quote {
  --blocks-quote--padding-left: var(--blocks-spacing-md);
  --blocks-quote--image-display: none;

  container-type: inline-size;

  blockquote {
    display: flex;
    flex-direction: column;
    gap: var(--blocks-spacing-md);
    padding-left: var(--blocks-quote--padding-left);

    &::before {
      content: url("../../icons/quote.svg");
      display: block;
    }
  }

  .blocks-quote--text {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-semibold);
  }

  .blocks-quote--image {
    display: var(--blocks-quote--image-display);
    max-width: 7rem;
    max-height: 7rem;
    border-radius: 50%;
  }
}

@container (min-width: 420px) {

  .blocks-quote > blockquote {
    --blocks-quote--padding-left: var(--blocks-spacing-xl);
  }

  .blocks-quote img {
    --blocks-quote--image-display: block;
  }
}
