.button {
  --button--background-color: var(--color-theme-base-default);
  --button--background-color-hover: var(--color-neutral-base-default);
  --button--color: var(--color-theme-contrast-default);
  --button--color-hover: var(--color-neutral-contrast-default);
  --button--padding-block: var(--spacing-xs);
  --button--padding-inline: var(--spacing-s);
  --button--text-size: var(--font-size-m);
  --button--font-weight: var(--font-weight-medium);

  padding: var(--button--padding-block) var(--button--padding-inline);
  border-radius: 70px;
  background-color: var(--button--background-color);
  font-size: var(--button--text-size);
  font-weight: var(--button--font-weight);
  color: var(--button--color);
  transition: background-color .1s linear, color .1s linear;

  &:hover {
    --button--background-color: var(--button--background-color-hover);
    --button--color: var(--button--color-hover);

    text-decoration: underline;
  }

  &:active {
    transform: translateY(1px);
  }
}

.button-large {
  --button--padding-block: var(--spacing-s);
  --button--padding-inline: var(--spacing-m);
  --button--text-size: var(--font-size-l);
  --button--font-weight: var(--font-weight-semibold);
}

.button-small {
  --button--padding-block: var(--spacing-xxs);
  --button--padding-inline: var(--spacing-xs);
  --button--text-size: var(--font-size-s);
  --button--font-weight: var(--font-weight-medium);
}
