.article-header {

  & figure {
    margin-block: var(--margin-s);

    video,
    img {
      width: 100%;
      height: auto;
    }
  }

  .icon-start.link-basic {
    text-decoration: none;

    --icon-url: url("../../../assets/icons/arrow-left.svg");
    --icon-color: var(--color-accent-base-default);
    --icon-height: 1.25rem;
    --icon-width: 1.25rem;
  }
}
