.lottie-logo-loaded {
  max-width: 154px;

  .item-logo {
    display: none;
  }

  svg {

    .color-base > g > path {
      fill: var(--logo-color-base);
    }

    .color-secondary > g > path {
      fill: var(--logo-color-secondary);
    }
  }
}

.mode-light {

  .theme-white {
    --logo-color-base: var(--color-neutral-text-default);
    --logo-color-secondary: var(--color-neutral-text-default);
  }

  .theme-turquiose-light {
    --logo-color-base: var(--color-neutral-text-default);
    --logo-color-secondary: var(--color-neutral-text-default);
  }
}

.mode-dark {
  --logo-color-base: var(--color-neutral-text-default);
  --logo-color-secondary: var(--color-turquoise-light);
}
