.related-content-part {
  background-color: var(--color-theme-base-default);
  color: var(--color-theme-contrast-default);
  padding-top: var(--margin-l);

  --content-grid--row-gap: var(--margin-l);

  .list-item-results {
    gap: var(--content-grid--row-gap);
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
