.page-grid {
  --page-grid--padding-inline: var(--spacing-s);
  --page-grid--full: minmax(var(--page-grid--padding-inline), 1fr);
  --page-grid--content: min(var(--page-container-sm), 100% - var(--page-grid--padding-inline) * 2);
  --page-grid--feature: minmax(0, calc((var(--page-container-lg) - var(--page-container-sm)) / 2));

  display: grid;
  grid-template-columns:
    [full-start] var(--page-grid--full)
    [feature-start] var(--page-grid--feature)
    [content-start] var(--page-grid--content) [content-end]
    var(--page-grid--feature) [feature-end]
    var(--page-grid--full) [full-end];
  row-gap: var(--page-grid--row-gap, var(--margin-s));
}

:root {
  --page-container-sm: 860px;
  --page-container-lg: 1300px;
}

.content-grid--layout-wide {
  --page-container-sm: 1080px;
}

.page-grid > * {
  grid-column: var(--page-grid-grid-column, content);
}

.page-grid--feature {
  --page-grid-grid-column: feature;
}

.page-grid--full {
  --page-grid-grid-column: full;
}

