.layout {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-inline: auto;
  width: 100%;
}

.container-xl {
  max-width: 1300px;
}

.container-l {
  max-width: 1080px;
}

.container-m {
  max-width: 1044px;
}

.container-s {
  max-width: 770px;
}

.container-max-center {
  max-width: var(--container-max-width);
  margin: auto;
}

.content-type-list-page, .content-type-site {

  .layout {
    padding-inline: var(--margin-s);
  }

  .container-l {
    max-width: 1120px;
  }

  .container-m {
    max-width: 1084px;
  }

  .container-s {
    max-width: 810px;
  }
}
