.layout-2-col {
  --layout-2-col-gap: var(--margin-m, 1rem);

  display: flex;
  flex-direction: column;
  gap: var(--layout-2-col-gap);

  > [data-portal-region="left"] {
    flex: 1;
  }

  > [data-portal-region="right"] {
    flex: 2;
  }

  @media (width > 840px) {
    flex-direction: row;
  }
}
