.text-accent {
  color: var(--color-accent-text-subtle);
}

.text-small {
  font-size: var(--font-size-s);
}

.text-italic {
  font-style: italic;
}

.inclusively-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.no-decoration {
  text-decoration: none;
}
