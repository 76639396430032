.chip {
  --chip--border-radius: 10px;
  --chip--text-color: var(--color-theme-text-default);
  --chip--background-color: var(--color-theme-base-default);
  --chip--checkbox-background-color: transparent;
  --chip--checkbox-border-color: var(--color-theme-contrast-subtle);
  --chip--checkbox-icon-color: transparent;

  display: inline-flex;
  align-items: center;
  padding: var(--padding-5) var(--padding-10);
  border-radius: var(--chip--border-radius);
  background-color: var(--chip--background-color);
  font-size: var(--font-size-150);
  font-weight: var(--font-size-500);
  color: var(--chip--text-color);
  transition: background-color .1s linear, color .1s linear;
  cursor: var(--chip--cursor, pointer);
  user-select: none;
  gap: var(--spacing-xs);

  &:hover {
    outline: 1px solid var(--color-theme-contrast-default);
  }

  &:has([type="checkbox"]:checked) {
    --chip--background-color: var(--color-theme-contrast-default);
    --chip--text-color: var(--color-theme-base-active);
    --chip--checkbox-border-color: var(--color-theme-base-active);
    --chip--checkbox-background-color: var(--color-theme-base-active);
    --chip--checkbox-icon-color: var(--color-theme-contrast-default);
  }

  input[type="checkbox"] {
    display: inline-flex;
    width: 20px;
    height: 20px;
    border: 2px solid var(--chip--checkbox-border-color);
    border-radius: 5px;
    background-color: var(--chip--checkbox-background-color);
    place-content: center;
    appearance: none;
    transition: background-color .1s linear;
    cursor: var(--chip--cursor, pointer);

    &::before {
      content: "";
      display: block;
      align-self: center;
      width: 12px;
      height: 9px;
      background-color: var(--chip--checkbox-icon-color);
      mask-repeat: no-repeat;
      transition: background-color .1s linear;
      mask-size: contain;
      mask-position: var(--icon-position);
      mask-image: url("../../../../assets/icons/check.svg");
    }

    &:focus {
      outline: none;
    }
  }

  &:has(input[type="checkbox"]:focus-visible) {
    outline: 4px solid var(--color-accent-base-active);
    outline-offset: 4px;
  }
}

.chip.theme-brand1 {
  --color-theme-base-default: var(--color-petroleum-80);
  --color-theme-base-active: var(--color-petroleum-9);
  --color-theme-contrast-default: var(--color-light-9);
  --color-theme-contrast-subtle: var(--color-light-9);
}

.chip.theme-brand3 {
  --color-theme-text-default: var(--color-petroleum-9);
  --color-theme-base-default: var(--color-light-9);
  --color-theme-base-active: var(--color-white);
  --color-theme-contrast-subtle: var(--color-petroleum-60);
  --color-theme-contrast-default: var(--color-petroleum-9);
}
