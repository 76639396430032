.video-block {

  .video {
    position: relative;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 */

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
