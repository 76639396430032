.flow > * + * {
  margin-top: var(--flow-space, var(--margin-s, 1.25rem));
}

.p-m {
  padding: var(--padding-20);
}

.pb-l {
  padding-bottom: var(--padding-40);
}

.pb-xxl {
  padding-bottom: var(--padding-80);
}

.pb-40 {
  padding-bottom: var(--padding-40);
}

.pb-60 {
  padding-bottom: var(--padding-60);
}

.pb-80 {
  padding-bottom: var(--padding-80);
}

.pb-120 {
  padding-bottom: var(--padding-120);
}

.pb-200 {
  padding-bottom: var(--padding-200);
}

.pt-40 {
  padding-top: var(--padding-40);
}

.pt-60 {
  padding-top: var(--padding-60);
}

.pt-80 {
  padding-top: var(--padding-80);
}

.pt-120 {
  padding-top: var(--padding-120);
}

.pt-200 {
  padding-top: var(--padding-200);
}

.mb-m {
  margin-bottom: var(--margin-m); /* 20px */
}

.mt-xs {
  margin-top: var(--margin-xs); /* 20px */
}

.mt-s {
  margin-top: var(--margin-s); /* 20px */
}

.mt-m {
  margin-top: var(--margin-m); /* 40px */
}

.mt-l {
  margin-top: var(--margin-l); /* 40px */
}

.mt-m-xl {
  margin-top: var(--margin-xl); /* 80px */
}

.mt-m-xxl {
  margin-top: var(--margin-xxl); /* 120px */
}

.ml-auto {
  margin-left: auto;
}

.gv-0 {
  row-gap: 0;
}

.gv-20 {
  row-gap: var(--padding-20);
}

.gv-40 {
  row-gap: var(--padding-40);
}

.gv-60 {
  row-gap: var(--padding-60);
}
