:root {
  --icon--close: url("../../icons/close.svg");
}

.icon,
.icon-start::before,
.icon-end::after {
  content: "";
  display: inline-block;
  width: var(--icon-width, var(--icon-size, 1lh));
  height: var(--icon-height, var(--icon-size, 1lh));
  background-color: var(--icon-color, var(--color-accent-base-default));
  vertical-align: top;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: var(--icon-position);
  mask-image: var(--icon-url);

  --icon-margin: var(--margin-xs);
}

*.icon:focus-visible {
  --icon-color: var(--color-accent-base-default);
}

.icon {
  --icon-position: center;
}

.icon-start::before {
  margin-right: var(--icon-margin);

  --icon-position: left;
}

.icon-end::after {
  margin-left: var(--icon-margin);

  --icon-position: right;
}

.icon-vertical-center {
  display: inline-flex;
  align-items: center;

  &::before {
    /* Don't allow the icon to shrink */
    flex-shrink: 0;
  }
}

[type="submit"].icon {
  color: transparent;
}

.icon--menu {
  --icon-url: url("../../icons/menu.svg");
  --icon-size: 32px;
}

.icon--search {
  --icon-url: url("../../icons/search.svg");
  --icon-size: 32px;
}
