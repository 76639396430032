pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: var(--spacing-s);
}

.blocks-factbox:has(code.hljs) {
  overflow-x: auto;

  code.hljs {
    padding: 0;
  }

  .highlighted {
    display: grid;
    grid-template-rows: subgrid;
    grid-column: breakout;

    highlight-code {
      grid-column: content;
    }
  }
}

.hljs {
  background: light-dark(var(--color-turquoise-light), var(--color-dark-9));
  color: light-dark(var(--color-black), var(--color-black-1));
}

.hljs-doctag,
.hljs-keyword,
.hljs-meta .hljs-keyword,
.hljs-template-tag,
.hljs-template-variable,
.hljs-type,
.hljs-variable.language_ {
  color: light-dark(#d73a49, #ff7b72);
}

.hljs-title,
.hljs-title.class_,
.hljs-title.class_.inherited__,
.hljs-title.function_ {
  color: light-dark(var(--color-purple), var(--color-purple-40));
}

.hljs-attr,
.hljs-attribute,
.hljs-literal,
.hljs-meta,
.hljs-number,
.hljs-operator,
.hljs-variable,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-id {
  color: light-dark(#005cc5, #79c0ff);
}

.hljs-regexp,
.hljs-string,
.hljs-meta .hljs-string {
  color: light-dark(#032f62, #a5d6ff);
}

.hljs-built_in,
.hljs-symbol {
  color: light-dark( #e36209, #ffa657);
}

.hljs-comment,
.hljs-code,
.hljs-formula {
  color: light-dark(#6a737d, #8b949e);
}

.hljs-name,
.hljs-quote,
.hljs-selector-tag,
.hljs-selector-pseudo {
  color: light-dark(#22863a, #7ee787);
}

.hljs-subst {
  color: light-dark(var(--color-black), var(--color-black-1));
}

.hljs-section {
  font-weight: bold;
  color: light-dark(#005cc5, #1f6feb);
}

.hljs-bullet {
  color: light-dark(#735c0f, #f2cc60);
}

.hljs-emphasis {
  font-style: italic;
  color: light-dark(var(--color-black), var(--color-black-1));
}

.hljs-strong {
  font-weight: bold;
  color: light-dark(var(--color-black), var(--color-black-1));
}

.hljs-addition {
  background-color: light-dark(#f0fff4, #033a16);
  color: light-dark( #22863a, #aff5b4);
}

.hljs-deletion {
  background-color: light-dark(#ffeef0, #67060c);
  color: light-dark(#b31d28, #ffdcd7);
}

.hljs-char.escape_,
.hljs-link,
.hljs-params,
.hljs-property,
.hljs-punctuation,
.hljs-tag {
  /* purposely ignored */

}
