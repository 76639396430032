.header {
  --header--background-color: var(--color-theme-base-default);
  --header--padding-block: var(--margin-s);
  --icon-size: 32px;
  --icon-color: var(--color-theme-contrast-default);
  --logo-color-base: var(--color-theme-contrast-default);
  --logo-color-secondary: var(--color-theme-contrast-default);

  position: relative;
  z-index: 2;

  @media (width > 1080px) {
    --header--padding-block: 1.875rem;
  }

  padding-block: var(--header--padding-block);
  padding-left: 0;
  background-color: var(--header--background-color);

  .header-inner   {
    display: flex;
    justify-content: space-between;
  }

  &:where(.theme-neutral) {
    --header--background-color: var(--color-white-9);
  }

  .logo-wrapper {

    &:has(> .item-logo) {
      /* If direct child it not lottie - adjust to fit Lottie sizes */

      padding-block: 6px;
      padding-inline: 3px;
    }

    .item-logo {
      width: 111px;
      height: 28px;

      .color-secondary {
        fill: var(--logo-color-secondary);
      }

      .color-base {
        fill: var(--logo-color-base);
      }
    }

    svg:not(.item-logo) {
      margin-left: -12%;
    }
  }

  .header-buttons {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: var(--button-spacing, var(--margin-m));
  }

  .header-button-darkmode {
    --icon-url: url('../../../assets/icons/moon.svg');

    color: var(--color-accent-contrast-default);
  }

  &:has(#search-menu:popover-open) [popovertarget="search-menu"] .icon,
  &:has(#page-menu:popover-open) [popovertarget="page-menu"] .icon {
    --icon-url: var(--icon--close);
  }

  /* Fallback for popover polyfill */

  &:has(#search-menu.\:popover-open) [popovertarget="search-menu"] .icon,
  &:has(#page-menu.\:popover-open) [popovertarget="page-menu"] .icon {
    --icon-url: var(--icon--close);
  }
}

@media (prefers-color-scheme: dark) {

  :root:not(.mode-light) {

    .header {
      --header--background-color: var(--color-neutral-background-default);
    }

    .header-button-darkmode {
      --icon-url: url('../../../assets/icons/sun.svg');
    }
  }
}

.mode-dark {

  .header {
    --header--background-color: var(--color-neutral-background-default);
  }

  .header-button-darkmode {
    --icon-url: url('../../../assets/icons/sun.svg');
  }
}

[popover].header-menu-popover {
  --header-menu-popover--padding: var(--padding-20);
  --header-height-mobile: 60px;
  --header-height-desktop: 100px;
  --header-height: var(--header-height-mobile);

  top: var(--header-height);
  opacity: 0;
  margin-inline: auto;
  padding: var(--header-menu-popover--padding);
  border: 0;
  background-color: transparent;
  color: var(--color-theme-contrast-default);
  transform: translateY(-100vh) scaleY(2.5) scaleX(0.2);
  filter: blur(40px);

  @media (width > 576px) {
    --header-menu-popover--padding: var(--padding-60);
  }

  @media (width > 1080px) {
    --header-height: var(--header-height-desktop);
  }

  /* Transitions related to opening the menu */
  @media (prefers-reduced-motion: no-preference) {
    opacity: 0;
    filter: blur(40px);
    transform: translateY(-100vh) scaleY(2.5) scaleX(0.2);
    transition:
      opacity 250ms,
      transform 250ms,
      filter 250ms,
      overlay 250ms,
      display 250ms;
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-behavior: allow-discrete;

    &:is(&:popover-open, &.\:popover-open) {
      opacity: 1;
      transform: translateY(0) scaleY(1) scaleX(1);
      filter: blur(0);
      transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
    }

    &::backdrop {
      top: var(--header-height);
      opacity: 0;
      background-color: var(--header--background-color);
      transform: scaleX(0);
      transition: opacity 250ms, transform 250ms, display 250ms;
      transition-behavior: allow-discrete;
      transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    }

    &:is(&:popover-open, &.\:popover-open)::backdrop {
      opacity: 1;
      transform: scaleX(1);
      transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);

      @starting-style {
        opacity: 0;
        transform: scaleX(0);
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      }
    }
  }
}

/* Prevent background scrolling when popover is open */

:root:is(
 &:has(.header-menu-popover:popover-open),
 &:has(.header-menu-popover.\:popover-open)
)
 {
  overflow-y: hidden;
}

