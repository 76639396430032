.blocks-factbox--contacts {
  display: grid;
  grid-template-columns: minmax(64px, 179px) 1fr;
  grid-gap: var(--margin-m);
  margin-top: var(--margin-m, 2.5rem);

  .blocks-factbox--contact {
    display: grid;
    align-items: center;
    grid-template-columns: subgrid;
    grid-column: 1 / span 2;
  }

  .blocks-factbox--contact-info {
    --flow-space: var(--margin-xs);

    display: flex;
    flex-direction: column;
  }

  .blocks-factbox--contact-title {
    --flow-space: 0
  }
}

/* Add horizontal padding if not in outermost content grid */

.content-grid .content-grid .blocks-factbox {
  --blocks-factbox--padding: var(--margin-l) var(--margin-m);

  @media (width > 840px) {
    --blocks-factbox--padding: var(--margin-l) var(--margin-xl);
  }
}
