.competence-view {
  --competence-view--level-size: 150px;
  --competence-view--grid-template-columns: 1fr;

  display: grid;
  grid-template-columns: var(--competence-view--grid-template-columns);
  font-size: var(--font-size-s);
  gap: var(--spacing-m);

  h3 {
    margin-bottom: var(--spacing-xs);
    font-size: var(--font-size-m);
  }

  .link-block-links {
    margin-top: var(--spacing-xs);
    padding: 0;
    list-style: none
  }

  .blocks-accordion details summary {
    --blocks-accordion--padding: 0;
  }

  .competence-level {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: var(--competence-view--level-size);
    height: var(--competence-view--level-size);
    border-radius: 50%;
    background-color: var(--color-theme-base-default);

    h3 {
      font-size: var(--font-size-s);
      text-align: center;
      text-transform: lowercase;
    }
  }

  @media only screen and (width > 576px) {

    &:has(.competence-level) {
      --competence-view--grid-template-columns: 1fr var(--competence-view--level-size);
    }
  }
}
