.blocks-content-list {
  display: flex;
  flex-direction: column;
  gap: var(--margin-m);
}

.blocks-content-list--header {
  display: flex;
  flex-direction: column;
  gap: var(--margin-xs);
}

.blocks-content-list--items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.blocks-content-list .blocks-content-list--item {
  --kicker-color: var(--color-accent-base-default);
  --text-color: currentcolor;
  --icon-color: var(--kicker-color);

  display: grid;
  grid-template-columns: auto 1fr;
  color: var(--text-color);
  text-decoration: none;
  row-gap: var(--spacing-xs);

  .kicker {
    display: flex;
    grid-area: kicker;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold);
    color: var(--kicker-color);
  }

  &.is-in-the-past {
    --kicker-color: light-dark(var(--color-gray), var(--color-lightgray));
    --text-color: light-dark(var(--color-gray), var(--color-lightgray));

    img {
      filter: saturate(0);
    }
  }

  &[href]:hover .blocks-content-list--title {
    text-decoration: underline;
  }

  &:has(img) {
    --content-list--body-padding: var(--padding-10);

    grid-template-columns: 1fr;
    column-gap: var(--spacing-s);

    @media (width > 576px) {
      grid-template-columns: 1fr 2fr;
    }
  }
}

.blocks-content-list .blocks-content-list--item-body {
  display: flex;
  flex-direction: column;
  padding-top: var(--content-list--body-padding);
  gap: var(--spacing-xxs);
}

.icon--calendar {
  --icon-url: url("../../../assets/icons/calendar.svg");
  --icon-size: 18px;
}

.icon--none {
  --icon-width: 18px;
  --icon-height: 0;
}
