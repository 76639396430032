.img-container {
  position: relative;

  .logo-wrapper {
    display: flex;
    place-content: center center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 150px;
    height: 67px;
    background-color: white;
  }
}
