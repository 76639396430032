table {

  & th,
  & td {
    padding: var(--spacing-xxs);
    border-right: 2px solid var(--color-neutral-text-default);
  }

  & th {
    font-weight: var(--font-weight-bold);
  }

  & tr {
    border-bottom: 2px solid var(--color-neutral-text-default);
  }

  & th:last-child,
  & td:last-child {
    border: 0;
  }
}

table.richtext-table {
  display: block;
  overflow-x: auto;
  width: 100%;

  & caption {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-xl);
  }

  & th,
  & td {
    white-space: nowrap;
  }
}
