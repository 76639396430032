.global-menu-container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-block: var( --padding-80);
  padding-inline: var(--padding-40);

  a {
    font-size: var(--font-size-xxl);
    font-weight: 600;
    text-decoration: none;

    &:hover {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
