@import url("@item-enonic-types/lib-blocks/dist/blocks-images.css");

.blocks-images {
  --block-images--expand-link-size: 33px;

  /* p2 */

  figcaption {
    margin-top: var(--margin-xxs);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-regular);
    font-style: normal;
    line-height: var(--line-height-xs);
  }

  .popover-gallery--button picture {
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      right: var(--margin-xs);
      bottom: var(--margin-xs);
      width: var(--block-images--expand-link-size);
      height: var(--block-images--expand-link-size);
    }

    &::before {
      border-radius: 50%;
      background-color: var(--color-neutral-background-default);
    }

    &::after {
      mask-image: url("../../icons/expand-link.svg");
      mask-position: center;
      background-color: var(--color-neutral-text-default);
    }
  }
}
