@import url("@item-enonic-types/lib-blocks/dist/blocks-content-grid.css");

:root {
  --blocks-container-sm: 640px;
  --blocks-container-md: 750px;
  --blocks-container-lg: 860px;
  --blocks-container-xl: 1080px;
}

.content-grid {
  --content-grid--padding-inline: var(--spacing-s);
}

.content-grid--layout-narrow,
.content-grid--layout-wide .content-grid .content-grid /* Keep small gap when nested */ {
  --content-grid--row-gap: var(--margin-l);
}

.content-grid--layout-wide {
  --content-grid--row-gap: var(--margin-xxl);
  --blocks-container-sm: 1080px;
  --blocks-container-md: 1190px;
  --blocks-container-lg: 1300px;
  --blocks-container-xl: 1410px;
}
