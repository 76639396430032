.html-area {

  ol, ul {

    li {
      margin-bottom: 6px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  .linker-arrow-big {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-medium);
  }

  a:not([class]) {
    text-decoration: underline;
  }

  a.linker-button {
    display: inline-block;
  }

  a.linker-button, a.linker-arrow, a.linker-arrow-big {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
