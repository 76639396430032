body {
  display: flex;
  flex-direction: column;

  main#main {
    flex: 1;
  }
}

.default-page [data-portal-region] {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: var(--content-grid--row-gap, var(--margin-s))
}

.region-header {
  padding-block: var(--padding-40);
}

.region-main,
.region-aside {
  padding-top: var(--padding-60);
}

/* Add padding to the last element to get the background color all the way down */

.pad-last-component > [data-portal-component-type]:last-child  {
  padding-bottom: var(--padding-200);
}

.mode-light {

  .region-header {
    background-color: var(--color-theme-base-default);
    color: var(--color-theme-text-default);
  }

  .main-bg-enabled {
    background-color: var(--color-theme-base-default);
  }
}

@media (prefers-color-scheme: light) {

  :root:not(.mode-dark) .region-header {
    background-color: var(--color-theme-base-default);
    color: var(--color-theme-text-default);
  }

  :root:not(.mode-dark) .main-bg-enabled {
    background-color: var(--color-theme-base-default);
  }
}
