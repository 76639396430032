.person {

  .info {
    padding-left: var(--padding-20);

    @media (width > 576px) {
      --flow-space: var(--margin-xxs);
    }
  }

  & img, & figure {
    flex-shrink : 0;
    width: 7rem;
    height: auto;
  }
}
