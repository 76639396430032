*:focus-visible {
  outline: var(--color-accent-base-default) solid 4px;
  outline-offset: 3px;
}

.form-search-input[type="search"]:focus-visible {
  border:  4px solid var(--color-accent-base-default) ;
  outline: none;
}

.screenreader-only {
  position: absolute;
  left: -10000px;
  top: auto;
  overflow: hidden;
  width: 1px;
  height: 1px;
}
