.list-item:hover  .title {
  text-decoration: underline;
}

.list-item-results {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--margin-m);

  @media (width > 639px) {
    grid-template-columns: 1fr 1fr;
    gap: var( --margin-m);
  }

  @media (width > 800px) {
    gap: var( --margin-xl);
  }

  .list-item {
    min-width: 0;
    min-height: 0;
    text-decoration: none;

    .img-container {
      position: relative;

      & .list-item-image {
        width: 100%;
      }
    }
  }
}

.list-item-results-row {
  display: flex;
  flex-direction: column;

  .list-item {
    display: flex;
    flex-direction: column;

    @media (width > 576px) {
      flex-direction: row;
      gap: 50px;
    }

    .img-container {
      flex-shrink: 0;
      width: 100%;

      @media (width > 576px) {
        width: 50%;
      }
    }

    .list-item-text-wrapper {
      @media (width > 576px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: var(--margin-xs);
      }
    }
  }
}

.list-item-results-global {

  .list-item {

    .rounded-circle {
      width: 200px;
    }

    .img-container {
      width: 100%;

      @media (width > 576px) {
        width: 200px;
      }

      .logo-wrapper {
        display: none;
      }
    }
  }

  .list-item-no-image {
    @media (width > 576px) {
      margin-left: 250px;
    }
  }

  .person {
    @media (width > 576px) {

      .rounded-circle {
        width: 210px;
      }
    }
  }
}
