.blocks-frontpage-links {
  display: flex;
  flex-direction: column;
  gap: var(--margin-xl);

  --frontpage-lottie-width-mini: 300px;
  --frontpage-lottie-width: 500px;

  @media only screen and (width > 720px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (width > 970px) {
    margin-top: 0;
  }

  .html-area {
    padding-left: var(--padding-40);

    ul {
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 30px;
      }

      li:last-child {
        margin-bottom: 0;
      }
    }
  }

  .frontpage-lottie {
    --lottie-extra-margin-adjustment: 80px;

    overflow: hidden;
    max-width: var(--frontpage-lottie-width);

    svg {
      /* A negative margin removes the extra whitespace above and below the animation */
      margin-block: -17%;
      /* Needs to overwrite svg[style] */
      height: auto ! important;
    }

    /** We got the decode animation with padding on side so we need to adjust for this on the right side **/
    @media only screen and (width > 720px) {
      max-width: var(--frontpage-lottie-width-mini);
    }

    @media only screen and (width > 970px) {
      max-width: var(--frontpage-lottie-width);

      svg {
        margin-left: var(--lottie-extra-margin-adjustment);
      }
    }

    .color-base, .color-secondary {
      filter: brightness(100%) contrast(100%);

      > path {
        filter: brightness(100%) contrast(100%);
      }
    }

    .color-base {
      fill: var(--color-neutral-text-default);
    }

    .color-secondary {
      fill: var(--color-accent-base-default);
    }
  }
}
