footer.footer {
  --footer-padding-block: 3.125rem;
  --footer--background-color: var(--color-theme-base-default);
  --footer-logo-color-base: var(--color-theme-contrast-default);
  --footer-logo-color-secondary: var(--color-theme-contrast-default);
  --footer-paragraph-spacing: var(--margin-xs);

  background-color: var(--footer--background-color);
  font-size: var( --font-size-150);
  padding-block: var(--footer-padding-block);
  color: var(--color-theme-contrast-default);

  @media only screen and (width > 576px) {
    --footer-padding-block: var(--margin-xl);
  }

  .item-logo {
    margin-bottom: var(--margin-m);

    .color-secondary {
      fill: var(--footer-logo-color-base);
    }

    .color-base {
      fill: var(--footer-logo-color-secondary);
    }
  }

  p {
    margin-bottom: var(--footer-paragraph-spacing);
  }

  .html-area a {
    font-weight: var(--font-weight-medium);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer-rich-text {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: var(--margin-m);

    ul {
      padding: 0;
      list-style: none;
    }
  }
}

