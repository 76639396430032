.sidebar-part {
  position: sticky;
  top: var(--margin-s);
  width: 100%;
  margin-bottom: var(--margin-l);
  background-color: var(--color-neutral-background-default);
  color: var(--color-neutral-text-default);

  @media (width > 840px) {
    flex-direction: row;
    margin-bottom: 0;
    padding-block: 0;
  }

  .icon-start {
    --icon-color: var(--color-accent-base-default);
    --icon-size: 24px;
  }

  & ul {
    --sidebar-anchor-gap: var(--margin-xs);

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    /* Uses same gap on desktop and mobile, not dynamic */
    gap: var(--spacing-s);

    a {
      font-weight: var(--font-weight-medium);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
