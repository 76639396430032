.search-form {
  width: 100%;

  .search-field {
    display: flex;
    align-items: end;
    flex-direction: column;
    gap: 20px;

    @media only screen and (width > 720px) {
      align-items: center;
      flex-direction: row;
    }
  }

  input[type="search"] {
    width: 100%;
    padding: 13px 40px;
    border: 4px solid var(--color-petroleum-60);
    border-radius: 60px;
    background-color: var(--color-white-1);
    font-size: var(--font-size-550);
    font-weight: var(--font-weight-semibold);
    color: var(--color-black);

    &::-webkit-search-cancel-button {
      content: "";
      appearance: none;
      width: 32px;
      height: 32px;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.22517 4.81096C5.83465 4.42044 5.20148 4.42044 4.81096 4.81096C4.42044 5.20148 4.42044 5.83465 4.81096 6.22517L10.5857 11.9999L4.81102 17.7746C4.4205 18.1651 4.42049 18.7983 4.81102 19.1888C5.20154 19.5793 5.83471 19.5793 6.22523 19.1888L11.9999 13.4141L17.7746 19.1888C18.1651 19.5793 18.7983 19.5793 19.1888 19.1888C19.5793 18.7983 19.5793 18.1651 19.1888 17.7746L13.4141 11.9999L19.1889 6.22517C19.5794 5.83465 19.5794 5.20148 19.1889 4.81096C18.7983 4.42044 18.1652 4.42044 17.7746 4.81096L11.9999 10.5857L6.22517 4.81096Z' fill='%23000814'/%3E%3C/svg%3E%0A");
      cursor: pointer;
     }
  }

  .button {
    --button--background-color: var(--color-accent-base-default);
    --button--color: var(--color-accent-contrast-default);

    padding: 20px 40px;
  }
}
